import React from 'react'
import Components from 'components/components'
import Header from 'components/layouts/Header'
import Footer from 'components/layouts/Footer'
import Marquee from "modules/Marquee";

class StoryblokEntry extends React.Component {
  
  render() {
    const {
      story,
      header,
      footer,
      ...rest
    } = this.props.pageContext;


    const marquee = {
      rows: [
        {
          items: [
            {
              text: "Branding"
            },
            {
              text: "E-Commerce"
            },
            
          ]
        },
        {
          items: [
            {
              text: "Digital Strategy"
            },
            {
              text: "SEO"
            },
            
          ],
        },
        {
          items: [
            {
              text: "GrebCommerce"
            },
            {
              text: "Social media"
            },
            
          ]
        }
      ]
    }

    return (
      <div>
        <Header blok={header.content} />
        <div id="main" className={story.content.component}>
          {React.createElement(Components(story.content.component), {
              key: story.content._uid,
              blok: story.content,
              name: story.name,
              tags: story.tag_list,
              full_slug: story.full_slug,
              ...rest
            })}

            
          <Marquee blok={marquee} />

        </div>
        <Footer blok={footer.content} />
      </div>
    )
  }
}

export default StoryblokEntry
